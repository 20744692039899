const permissions = [
  {
    title: 'Areas',
    key: 'areas',
    fields: [
      { text: 'Fulltax', value: 'fulltax' },
      { text: 'Estate', value: 'estate' },
      { text: 'Home', value: 'home' },
      { text: 'Auto', value: 'auto' },

      { text: 'Elder Care', value: 'elder' },
      { text: 'Creditor Protection', value: 'creditor' },
      { text: 'Property Tax', value: 'property' },
      { text: 'Divorce Planning', value: 'divorce' },
      { text: 'Life Insurance', value: 'life' },
      { text: 'Mortgage', value: 'mortgage' },
      { text: 'Long Term Care', value: 'ltc' },
      { text: 'Student Debt', value: 'student' },
      { text: 'Identity Theft', value: 'identity' },
      { text: 'Medical Insurance', value: 'medical' },
      { text: 'Disability Insurance', value: 'disability' },
      { text: 'Cross Border Planning', value: 'crossborder' },

      //{ text: 'General', value: 'general' },
      { text: 'Expense', value: 'expense' },
      { text: 'Basicestate', value: 'basicestate' },
      { text: 'Retirement', value: 'retirement' },
      { text: 'Investment', value: 'investment' },
    ],
  },
  {
    title: 'Snapshots',
    key: 'snapshots',
    fields: [
      { text: 'Fulltax', value: 'fulltax' },
      { text: 'Estate', value: 'estate' },
      { text: 'Home', value: 'home' },
      { text: 'Auto', value: 'auto' },
    ],
  },
  {
    title: 'Simulators',
    key: 'simulators',
    fields: [
      { text: 'Roth', value: 'roth' },
      { text: 'Estatelab', value: 'estatelab' },
      { text: 'Tax Projector', value: 'tax_projector' },
    ],
  },
  {
    title: 'Insights',
    key: 'insights',
    fields: [
      { text: 'Insights Access', value: 'access' },
      { text: 'Custom Insights', value: 'custom_insights' },
      { text: 'Company Library', value: 'company_library' },
    ],
  },
  {
    title: 'Task Manager',
    key: 'task_manager',
    fields: [
      { text: 'Task Manager Access', value: 'access' },
      { text: 'Edit Insights', value: 'edit_insights' },
      { text: 'Show Actions', value: 'show_actions' },
    ],
  },
  {
    title: 'Reports',
    key: 'reports',
    fields: [{ text: 'Reports Access', value: 'access' }],
  },
  {
    title: 'Settings',
    key: 'settings',
    fields: [{ text: 'Prospects', value: 'prospects' }],
  },
];

export default permissions;
